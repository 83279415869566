import React from 'react';
import { Card, Col, Row } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';

const CustomBikes = () => (
	<Layout>
		<SEO
			title="FAQ"
			description="Foire aux questions des prestations et services de notre garage et atelier moto."
			lang="fr"
			url="/faq"
		/>

		<div className="box-container first">
			<div className="content-block">
				<div className="content-block-bg" />
				<h2>FAQ</h2>


				<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', textAlign: 'left' }}>
					<h3>Quels sont les moyens de paiement acceptés par Black Way Motorcycles ?</h3>
					<p>Nous acceptons :</p>
					<ul>
						<li>Cash / Espèces (CHF)</li>
						<li>PostCard</li>
						<li>MasterCard</li>
						<li>Visa</li>
					</ul>
					<p>Selon le montant de la facture, les payements par carte de crédit peuvent être majorés du taux d’intérêt de votre fournisseur de carte (généralement 1.5%)</p>

					<h3 style={{ marginTop: 40 }}>Quel est le tarif horaire de l'atelier ?</h3>
					<p>Le tarif horaire de l'atelier est de CHF 120/heure.</p>
					<p>Tous les mécaniciens sont diplômés et suivent régulièrement des formations continues.</p>

					<h3 style={{ marginTop: 40 }}>Combien coûte un devis pour des réparations ?</h3>
					<p>Le prix d'un devis varie s’il s'agit d'un problème mécanique, électrique ou consécutif à un accident.</p>
					<p>Le devis est facturé selon le temps passé en atelier (CHF 120/heure).</p>

					<h3 style={{ marginTop: 40 }}>Est-ce que Black Way Motorcycles reprend ma moto ou mon scooter d'occasion ?</h3>
					<p>A l'achat d'un véhicule neuf,  nous étudions avec vous les conditions de reprises de votre deux-roues d'occasion. En règle générale, nous préférons le dépôt-vente.</p>
					<p>Nous n'achetons pas de deux-roues d'occasion sans achat d'un véhicule neuf.</p>

					<h3 style={{ marginTop: 40 }}>Faites-vous de la location  de motos ?</h3>
					<p>Nous ne faisons pas de locations motos pour passer le permis de conduire ou pour un week-end.</p>
					<p>Cependant nous mettons à disposition gratuitement un scooter de prêt lors des réparations de votre moto dans notre atelier.</p>

				</Card>
			</div>
		</div>
	</Layout>
);

export default CustomBikes;
